<template lang="pug">
.step-field-mapping
  .title {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'theMarketer' }) }}
  .d-flex.fields-wrapper.w-100
    .d-flex.fields-title
      .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
      .arrow-block
      .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'theMarketer' }) }}
    .d-flex.fields(v-for="(binding, index) in formBindings")
      binding-row.w-100.justify-content-between(
        :isNewFlow="true"
        :combinedSelect="true"
        :binding="binding"
        :index="index"
        :fields="fields"
        :type="settings.type"
        :hasError="bindingRowHasError(binding)"
      )
  .binding-error-message(v-if="missingRequiredFields.length") {{ bindingErrorMessage }}
  .d-flex.additional-data
    .d-flex.additional-data-row
      label.d-flex(for="additionalDataCheckbox")
        input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
        .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
  transition(name="fade")
    .d-flex.other-fields(v-if="checkboxValue")
      .d-flex.fields-wrapper.w-100
        .d-flex.fields-title
          .om-fields-header {{ $t('integrationFlow.integrationModal.general.customValue') }}
          .arrow-block
          .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'theMarketer' }) }}
        .d-flex.fields(v-for="(binding, index) in campaignDataBindings")
          .om-campaign-additional-fields.w-100
            binding-row.w-100.justify-content-between(
              :isNewFlow="true"
              :combinedSelect="true"
              :binding="binding"
              :index="index"
              :fields="fields"
              :type="settings.type"
            )
        .d-flex.fields(v-if="staticBindings.length > 0" v-for="(binding, index) in staticBindings")
          .om-campaign-static-fields.w-100
            binding-row.w-100.justify-content-between(
              :isNewFlow="true"
              :combinedSelect="true"
              :binding="binding"
              :index="index"
              :fields="fields"
              :type="settings.type"
              @removeStaticRow="deleteStaticField(index)"
            )
        .add-fields
          .d-flex.cursor-pointer(@click="addStaticField")
            om-button(icon="plusCircle" iconSize="1.715em" iconOnly style="color: #ed5a29")
            .font-size-0--875.font-weight-semibold.line-height-1.align-self-center {{ $t('integrationFlow.integrationModal.general.addCustomValue') }}
</template>

<script>
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import BindingRow from '@/components/IntegrationBindings/Row';
  import TagMultiselect from '@/components/IntegrationBindings/TagMultiselect';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
      TagMultiselect,
    },

    mixins: [bindingMixin],

    data() {
      return {
        fields: [
          {
            key: 'email',
            value: 'email',
          },
          {
            key: 'first_name',
            value: 'first name',
          },
          {
            key: 'last_name',
            value: 'last name',
          },
          {
            key: 'phone',
            value: 'phone',
          },
        ],
      };
    },

    mounted() {
      this.$on('isBindingModified', (value) => this.$emit('isModified', value));
    },
  };
</script>

<style lang="sass">
  .theMarketer
    .step-field-mapping
      .ds-toast-content
        font-size: 12px
        margin-top: 3px
      .other-fields
        .multiselect__tag
          padding-right: 1.75rem !important
        .multiselect__tags-wrap
          max-height: none
</style>
